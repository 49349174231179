import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styled from "styled-components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import useWindowSize from "../utils/useWindowSize";

import {Scanner} from "../stories/spinners/scanner/scanner";
import {Button} from "../stories/buttons/button";
import {Preview} from "./Preview";
import {Details} from "./Details";


export const Scan = ({hmac, base64, params, ...props}) => {
    const {width} = useWindowSize();
    const [status, setStatus] = useState('scan');
    const [scanText, setScanText] = useState('Scanning link, please wait...');
    const [errorMessage, setErrorMessage] = useState(null)

    const [results, setResults] = useState({
        url: '',
        content: [],
        risks: [],
        imageData: '',
        redirects: [],
        documents: [],
        whitelisted: false,
        isSecure: null,
        isFile: false
    })


    // Screenshot
    let useScreenshot = parseInt(params.get('i'));
    if ([0, 1].includes(useScreenshot) === false) {
        useScreenshot = 0;
    }

    // Autocontinue
    let autoContinue = parseInt(params.get('c'));
    if ([0, 1, 2, 3, 4, 5].includes(autoContinue) === false) {
        autoContinue = 0;
    }

    // Disable screenshot if autocontinue is 1,3 or 5
    if ([1, 3, 5].includes(autoContinue)) {
        useScreenshot = 0;
    }

    // Scan docs
    let scanDocs = parseInt(params.get('docs'));
    if ([0, 1].includes(scanDocs) === false) {
        scanDocs = 0;
    }

    // Scan mode
    let scanMode = parseInt(params.get('s'));
    if ([1, 2, 3].includes(scanMode) === false) {
        scanMode = 1;
    }

    let uuid = params.get('uuid') ? '&uuid=' + params.get('uuid') : ''
    let ssl = params.get('nosslverify') ? '&nosslverify=' + params.get('nosslverify') : ''
    let qr = params.get('qr') ? '&qr=' + params.get('qr') : ''
    let error = false;

    useEffect(() => {
        if (status === 'scan') {
            axios.get(`${process.env.REACT_APP_API_URL}/scan/${base64}/${hmac}?i=${useScreenshot}&docs=${scanDocs}&s=${scanMode}${uuid}${ssl}${qr}`)
                .then(
                    (response) => {
                        if (response.data.hasOwnProperty('errorType')) {
                            setStatus('warn')
                            setErrorMessage(response.data.errorMessage)
                        } else {
                            if (response.data.data.isSecure === false && response.data.data.isFile === true) {
                                setStatus('warn')
                                setScanText('Insecure (HTTP) link')
                            } else {
                                setStatus(response.data.data.risks.length > 0 ? 'warn' : 'clean')
                                setScanText('Scan complete')
                            }
                            setResults({
                                url: response.data.data.originalUrl,
                                content: response.data.data.content,
                                risks: response.data.data.risks,
                                imageData: response.data.data.screenshot,
                                redirects: response.data.data.redirects,
                                documents: response.data.data.documents,
                                whitelisted: response.data.data.whitelisted,
                                isSecure: response.data.data.isSecure,
                                isFile: response.data.data.isFile
                            })

                        }
                    },
                    (error) => {
                        setStatus('warn')
                        console.log(error)
                        switch (error.message) {
                            case 'Network Error':
                                setErrorMessage("Network Timeout" +
                                    "<p style='text-align: justify;'>This is likely due to the remote site being slow to respond, unavailable or temporary connectivity issues</p>" +
                                    "<p style='text-align: justify;'>Please try again shortly</p>" +
                                    "<p style='text-align: justify;'>If the problem persists, please contact your network administrator.</p>")
                                break;
                            default:
                                setErrorMessage(error.message)
                        }

                    }
                )
        }

    }, [autoContinue, scanDocs, scanMode, useScreenshot, uuid, status, error, ssl, hmac, base64, qr])

    useEffect(() => {
        let timer;
        if (status === 'scan') {
            timer = setTimeout(() => {
                setScanText('It is taking longer than expected to scan this link, please be patient...');
            }, 10000); // 15 seconds
        }

        // Cleanup the timer if the component is unmounted or if the status changes
        return () => clearTimeout(timer);
    }, [status, setScanText]);
    if ((status === 'clean' && [1, 3, 5].includes(autoContinue)) || results.whitelisted === true) {
        console.log('redirecting')
        window.location.href = results.url
    }
    if (status === 'scan' || (status === 'clean' && [1, 3, 5].includes(autoContinue)) || (status === 'warn' && errorMessage != null)) {
        return (
            <ScanDiv>
                {status === 'scan' &&
                    <ScanText status={status}>{scanText}</ScanText>
                }
                <Scanner status={status}></Scanner>
                {status === 'clean' &&
                    <div>
                        <Button onClick={() => {
                            window.location.replace(results.url);
                        }} label='CONTINUE' buttonType='clean'/>
                    </div>
                }
                {errorMessage != null &&
                    <div dangerouslySetInnerHTML={{__html: errorMessage}}></div>
                }
            </ScanDiv>
        )
    } else {
        return (
            <Container>
                <Header clean={status === 'clean'}/>
                <Title clean={status === 'clean'}>{results.risks.length > 0 &&
                    <StyledIcon
                        icon={faExclamationTriangle}/>}{results.risks.length === 0 ? scanText : 'Unsafe link detected, use caution!'}</Title>
                {width >= 900 &&
                    <Row>
                        {useScreenshot === 1 &&
                            <RowItem maxWidth={'500px'}>
                                <Preview maxWidth={500} clean={status === 'clean'} imageData={results.imageData}
                                         titleText={'Is this the page you were expecting from this link?'}/>
                            </RowItem>
                        }
                        <RowItem maxWidth={'400px'}>
                            <Details results={results} status={status} autoContinue={autoContinue}/>
                        </RowItem>
                    </Row>
                }
                {width < 900 &&
                    <Column>
                        <ColumnItem maxWidth={width}>
                            <Details results={results} status={status} autoContinue={autoContinue}/>
                        </ColumnItem>
                        {useScreenshot === 1 &&
                            <ColumnItem maxWidth={width}>
                                <Preview maxWidth={width < 500 ? (width - 30) : 500} clean={status === 'clean'}
                                         imageData={results.imageData}
                                         titleText={'Is this the page you were expecting from this link?'}/>
                            </ColumnItem>
                        }
                    </Column>
                }
                {[2, 3].includes(autoContinue) && <StyledFooter>{results.url}</StyledFooter>}
            </Container>
        )
    }


}

const ScanDiv = styled.div`
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
`

const ScanText = styled.h3`
    text-align: center;
    margin-bottom: 50px;
    color: ${props => {
        switch (props.status) {
            case 'clean':
                return props.theme.colors.base.green
            default:
                return props.theme.colors.base.grey
        }
    }}
`

const StyledIcon = styled(FontAwesomeIcon)`
    margin-left: 5px;
`;

const Header = styled.div`
    width: 100%;
    margin: 0px;
    height: 8px;
    background-color: ${props => {
        if (props.clean === true) {
            return props.theme.colors.base.green
        } else {
            return props.theme.colors.base.red
        }
    }};
`
const Title = styled.h1`
    color: ${props => {
        if (props.clean === true) {
            return props.theme.colors.base.green
        } else {
            return props.theme.colors.base.red
        }
    }};
    font-size: 50px;
    font-weight: 300;
    letter-spacing: -1px;
    line-height: 60px;
    margin: 50px 0px 20px;
`
const Container = styled.div`
    text-align: center;
`

const Row = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    gap: 90px;
    grid-auto-flow: column;
`
const RowItem = styled.div`
    width: ${props => props.maxWidth}
`

const Column = styled.div`
    width: ${props => props.maxWidth}
`

const ColumnItem = styled.div`
    width: ${props => props.maxWidth};
    padding-left: 10px;
    padding-right: 10px;
`
const StyledFooter = styled.div`
    max-width: 900px;
    font-size: 11px;
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
`
